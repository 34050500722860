import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./sites.module.css";
import { get } from "util/requests";
import router from "routes";
import LoadingButton from "@mui/lab/LoadingButton";

export default function SiteTable(props) {
  const { studyConfig } = props;
  const study_id = useSelector((state) => state.study.active_study_id);
  const [siteData, setSiteData] = useState([]);
  useEffect(() => {
    get(`/sites/${study_id}`).then((ret) => setSiteData(ret.data));
    // document.addEventListener("mousedown", handleDocumentClick);
  }, []);
  let columns = [
    { field: "code", headerName: "Site ID", width: 160 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "location", headerName: "Location", width: 150 },
    {
      field: "time_zone",
      headerName: "Time Zone",
      width: 150,
    },
    {
      field: "is_enabled",
      headerName: "Enabled",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => (row.is_enabled ? "YES" : "NO"),
    },
  ];

  if (studyConfig.etms) {
    columns.push(
      {
        field: "is_econsent_enabled",
        headerName: "eConsent Enabled",
        width: 160,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (row.is_econsent_enabled ? "YES" : "NO"),
      },
      {
        field: "is_randomization_enabled",
        headerName: "Randomization Enabled",
        width: 200,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (row.is_randomization_enabled ? "YES" : "NO"),
      },
      {
        field: "crf_version",
        headerName: "CRF Version",
        width: 140,
        headerAlign: "center",
        align: "center",
      },
    );
  }
  if (studyConfig.corelab) {
    columns.push({
      field: "corelab",
      headerName: "Corelab Enabled",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => (row.corelab ? "YES" : "NO"),
    });
  }

  return (
    <div>
      <LoadingButton
        variant="contained"
        loading={false}
        style={{ marginBottom: 6 }}
        onClick={() => router.navigate("/admin/site/add")}
      >
        ADD SITE
      </LoadingButton>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={siteData}
          columns={columns}
          disableColumnSelector
          disableRowSelectionOnClick
          disableColumnMenu
          pageSize={5}
          rowsPerPageOptions={[5]}
          hideFooterSelectedRowCount
          pageSizeOptions={[]}
          columnHeaderHeight={34}
          rowHeight={34}
          sx={{
            boxShadow: 0,
            border: 1,
            borderColor: "#f0f0f0",
            "& .MuiDataGrid-cell:hover": {
              color: "green",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9f1fd",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: 700,
              color: "#333",
            },
          }}
          onRowClick={(row) => {
            router.navigate(`/admin/sites/${row.id}`);
          }}
        />
      </div>
    </div>
  );
}
