import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { get, post, _delete } from "util/requests";
import styles from "./consent_upload.module.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Dialog, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import router from "routes";
import RadioGroup from "ui/crfs/radio_group";
import Select from "ui/crfs/select";
import DateTime from "ui/crfs/date_time";
import Text from "ui/crfs/text";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import { arrayHaveCommonItems } from "util/helpers";

export default function ConsentUpload(props) {
  const {
    showingModal = false,
    setShowingModal,
    subject_id,
    RefetchTableData,
    study_id,
  } = props;

  const permissions = useSelector((state) => state.user.userData.permissions);

  const [spinning, setSpinning] = useState(false);
  const [deleteSpinning, setDeleteSpinning] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [files, setFiles] = useState([]);
  const [valueObj, setValueObj] = useState({});
  const dropMenuRef = useRef(null);
  const uploadRef = useRef(null);

  useEffect(() => {
    if (showingModal.constructor === Boolean) {
      setValueObj({});
      setFiles([]);
    } else {
      console.log(showingModal);
      setValueObj({
        domains: { value: JSON.stringify(showingModal.domains) || [] },
        consent_type: { value: showingModal.consent_type },
        external_file_ref: { value: showingModal.external_file_ref },
        datetime: {
          value:
            showingModal.consent_date ||
            moment(showingModal.created_on).format("yyyy-MM-DD"),
          value2:
            showingModal.consent_time ||
            moment(showingModal.created_on).format("HH:mm"),
        },
        consent_method: { value: showingModal.consent_method },
        agreement_to_participate: {
          value: showingModal.agreement_to_participate,
        },
        collect_optional_data: { value: showingModal.collect_optional_data },
        withdrawn_data_use: { value: showingModal.withdrawn_data_use },
        consent_to_contact: { value: showingModal.consent_to_contact },
        file_id: showingModal.file_id || null,
        consent_id: showingModal.consent_id,
      });
      if (showingModal.file_name) {
        setFiles([{ name: showingModal.file_name, id: showingModal.file_id }]);
      }
    }
    setDeleteConfirm(false);
    setDeleteSpinning(false);
  }, [showingModal]);

  const handleFileChange = (e) => {
    setFiles([e.target.files[0]]);
  };
  console.log(files);

  const submit_form = (is_update = false) => {
    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append(
      "data",
      JSON.stringify({
        valueObj,
        subject_id,
        is_update,
        consent_id: showingModal.consent_id || null,
        study_id,
      }),
    );

    post("/consent_update", formData, {
      "Content-Type": "multipart/form-data",
    }).then(() => {
      setShowingModal(false);
      setSpinning(false);
      RefetchTableData();
    });
  };

  const delete_form = () => {
    setDeleteSpinning(true);
    _delete(
      `/consent_record/${subject_id}/${valueObj.consent_id}?study_id=${study_id}`,
    ).then(() => {
      setDeleteSpinning(false);
      setShowingModal(false);
      RefetchTableData();
    });
  };

  const openFile = (id, name) => {
    get(`/presigned_url/${id}`).then((ret) => {
      window.open(ret.data.url, "_blank");
    });
  };

  const title =
    showingModal.constructor === Boolean
      ? "Add Consent Record"
      : "Consent Details";

  const submitDisabled = !(
    valueObj.domains?.value &&
    valueObj.consent_type?.value &&
    valueObj.datetime?.value
  );
  const read_only = !permissions.includes("consent_record_update");
  return (
    <Dialog
      open={showingModal}
      onClose={() => setShowingModal(false)}
      maxWidth={800}
    >
      <div className={styles.modal_wrapper}>
        <div className={styles.modal_title}>{title}</div>
        <input
          ref={uploadRef}
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <div style={{ width: "100%" }}>
          <Select
            field={{
              value_obj: valueObj.domains || {},
            }}
            options={
              study_id === 1
                ? [
                    { value: "act42", label: "NoNo-42" },
                    { value: "actwhen", label: "Intravenous Thrombolysis" },
                    { value: "bp", label: "Blood Pressure" },
                  ]
                : [{ value: "ecore", label: "Escape-Core" }]
            }
            title={"Domains the consent is for:"}
            orientation={"vertical"}
            onUpdate={(value_obj) => {
              let data = { ...valueObj };
              data.domains = value_obj;
              setValueObj(data);
            }}
            read_only={read_only}
          />
        </div>
        <div style={{ width: "100%" }}>
          <RadioGroup
            field={{
              value_obj: valueObj.consent_type || {},
            }}
            options={[
              { value: "deferred_waiver_consent", label: "Deferred" },
              { value: "2_physician_emerg", label: "2 Physician Emergency" },
              { value: "patient", label: "Patient" },
              { value: "surrogate", label: "Surrogate" },
              { value: "regained_capacity", label: "Regained Capacity" },
            ]}
            title={"Type of consent:"}
            orientation={"vertical"}
            onUpdate={(value_obj) => {
              let data = { ...valueObj };
              data.consent_type = value_obj;
              setValueObj(data);
            }}
            read_only={read_only}
          />
        </div>
        <div style={{ width: "100%" }}>
          <DateTime
            field={{
              value_obj: valueObj.datetime || {},
            }}
            title={"Date and time of consent:"}
            onUpdate={(value_obj) => {
              console.log(value_obj);
              let data = { ...valueObj };
              data.datetime = value_obj;
              setValueObj(data);
            }}
            read_only={read_only}
          />
        </div>
        <div style={{ width: "100%" }}>
          <RadioGroup
            field={{
              value_obj: valueObj.consent_method || {},
            }}
            options={[
              { value: "paper", label: "Paper" },
              { value: "electronic", label: "Electronic" },
            ]}
            title={"Media of Consent:"}
            orientation={"vertical"}
            onUpdate={(value_obj) => {
              let data = { ...valueObj };
              data.consent_method = value_obj;
              setValueObj(data);
            }}
            read_only={read_only}
          />
        </div>
        <div style={{ width: "100%" }}>
          <RadioGroup
            field={{
              value_obj: valueObj.agreement_to_participate || {},
            }}
            options={[
              { value: "stay", label: "Stay in study" },
              { value: "withdraw", label: "Withdraw from study" },
            ]}
            title={"Agreement to Participate:"}
            orientation={"vertical"}
            onUpdate={(value_obj) => {
              let data = { ...valueObj };
              data.agreement_to_participate = value_obj;
              setValueObj(data);
            }}
            read_only={read_only}
          />
        </div>
        <div style={{ width: "100%" }}>
          <RadioGroup
            field={{
              value_obj: valueObj.collect_optional_data || {},
            }}
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            title={"Consent to collect optional data:"}
            orientation={"vertical"}
            onUpdate={(value_obj) => {
              let data = { ...valueObj };
              data.collect_optional_data = value_obj;
              setValueObj(data);
            }}
            read_only={read_only}
          />
        </div>
        <div style={{ width: "100%" }}>
          <RadioGroup
            field={{
              value_obj: valueObj.withdrawn_data_use || {},
            }}
            options={[
              { value: "yes", label: "Can use" },
              { value: "no", label: "Do not use" },
            ]}
            title={"Usage of optional data if withdrawn:"}
            orientation={"vertical"}
            onUpdate={(value_obj) => {
              let data = { ...valueObj };
              data.withdrawn_data_use = value_obj;
              setValueObj(data);
            }}
            read_only={read_only}
          />
        </div>
        <div style={{ width: "100%" }}>
          <RadioGroup
            field={{
              value_obj: valueObj.consent_to_contact || {},
            }}
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            title={"Consent to contact participant:"}
            orientation={"vertical"}
            onUpdate={(value_obj) => {
              let data = { ...valueObj };
              data.consent_to_contact = value_obj;
              setValueObj(data);
            }}
            read_only={read_only}
          />
        </div>

        <div style={{ width: "100%" }}>
          <Text
            field={{
              value_obj: valueObj.external_file_ref || {},
            }}
            title={"External File Reference # (optional)"}
            onUpdate={(value_obj) => {
              let data = { ...valueObj };
              data.external_file_ref = value_obj;
              setValueObj(data);
            }}
            read_only={read_only}
          />
        </div>
        <div style={{ width: "100%" }}>
          <div className={styles.input_flex}>
            <div className={styles.left_split}>
              Consent File Upload (optional)
            </div>
            {files.length ? (
              <div className={styles.files_wrapper}>
                {files.map((f) => (
                  <div className={styles.file_display_wrapper}>
                    <div
                      key={f.name}
                      className={
                        arrayHaveCommonItems(permissions, [
                          "consent_uploaded_document_view",
                          "consent_record_update",
                        ])
                          ? styles.filename_clickable
                          : styles.filename
                      }
                      onClick={() => {
                        if (
                          arrayHaveCommonItems(permissions, [
                            "consent_uploaded_document_view",
                            "consent_record_update",
                          ])
                        ) {
                          openFile(f.id, f.name);
                        }
                      }}
                    >
                      {f.name}
                    </div>
                    {!read_only ? (
                      <div className={styles.clear_button}>
                        <ClearIcon
                          onClick={() => {
                            setFiles([]);
                            let data = { ...valueObj };
                            data.file_id = null;
                            setValueObj(data);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : read_only ? (
              "---"
            ) : (
              ""
            )}
            {!files.length && !read_only ? (
              <div className={styles.right_split}>
                <LoadingButton
                  variant="contained"
                  loading={false}
                  onClick={() => uploadRef.current.click()}
                  color="inherit"
                  sx={{ color: "#555" }}
                >
                  Add File
                </LoadingButton>
              </div>
            ) : null}
          </div>
        </div>

        {showingModal.constructor === Boolean && showingModal ? (
          <React.Fragment>
            <div className={styles.modal_button_wrapper}>
              <LoadingButton
                disabled={submitDisabled}
                variant="contained"
                loading={spinning}
                onClick={() => {
                  setSpinning(true);
                  submit_form();
                }}
              >
                Submit
              </LoadingButton>
              <LoadingButton
                disabled={false}
                variant="outlined"
                loading={false}
                onClick={() => setShowingModal(false)}
              >
                Cancel
              </LoadingButton>
            </div>
          </React.Fragment>
        ) : null}
        {showingModal.constructor !== Boolean &&
        !deleteConfirm &&
        permissions.includes("consent_record_update") ? (
          <React.Fragment>
            <div className={styles.modal_button_wrapper}>
              <LoadingButton
                disabled={false}
                variant="contained"
                loading={spinning}
                onClick={() => {
                  setSpinning(true);
                  submit_form(true);
                }}
              >
                Update
              </LoadingButton>

              <LoadingButton
                disabled={false}
                variant="contained"
                loading={false}
                onClick={() => {
                  setDeleteConfirm(true);
                }}
                color="inherit"
                sx={{ color: "#555" }}
              >
                Delete
              </LoadingButton>
              <LoadingButton
                disabled={false}
                variant="outlined"
                loading={false}
                onClick={() => setShowingModal(false)}
              >
                Cancel
              </LoadingButton>
            </div>
          </React.Fragment>
        ) : null}
        {showingModal.constructor !== Boolean &&
        deleteConfirm &&
        showingModal &&
        permissions.includes("consent_record_update") ? (
          <div className={styles.delete_confirmation_wrapper}>
            <div style={{ width: "100%" }}>
              Are you sure you want to delete this form?
            </div>
            <div className={styles.confirm_delete_button_wrapper}>
              <LoadingButton
                disabled={false}
                variant="contained"
                loading={deleteSpinning}
                onClick={() => {
                  delete_form();
                }}
              >
                Yes
              </LoadingButton>

              <LoadingButton
                disabled={false}
                variant="outlined"
                loading={false}
                onClick={() => setDeleteConfirm(false)}
              >
                Cancel
              </LoadingButton>
            </div>
          </div>
        ) : null}
        {showingModal.constructor !== Boolean &&
        !permissions.includes("consent_record_update") ? (
          <div className={styles.modal_button_wrapper}>
            <LoadingButton
              disabled={false}
              variant="outlined"
              loading={false}
              onClick={() => setShowingModal(false)}
            >
              Close
            </LoadingButton>
          </div>
        ) : null}
      </div>
    </Dialog>
  );
}
