import React, { useState, useEffect } from "react";
import styles from "./randomize.module.css";
import { post } from "util/requests";
import LoadingButton from "@mui/lab/LoadingButton";
import RadioGroup from "ui/crfs/radio_group";
import RadioGroupUI from "ui/crfs/radio_group/components/radio_group_ui";
import DropDown from "ui/crfs/drop_down";
import DateTimeUI from "ui/crfs/date_time/components/date_time_ui";
import BloodPressureUI from "ui/crfs/blood_pressure/components/blood_pressure_ui";
import YearUI from "ui/crfs/year/components/year_ui";
import IntUI from "ui/crfs/int/components/int_ui";
import WeightUI from "ui/crfs/weight/components/weight_ui";
import { TextField } from "@mui/material";
import DividerLine from "ui/crfs/divider_line";
import { useParams } from "react-router-dom";
import { get } from "util/requests";
import { Checkbox } from "@mui/material";
import RandomizeModal from "./components/randomize_modal";
import router from "routes";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";

export const renderQuestion = (data, setData, item, setParentFieldError) => {
  if (item.type === "datetime")
    return (
      <React.Fragment key={item.key}>
        <div className={styles.row}>
          <div className={styles.label}>{item.text}</div>
          <div className={styles.center_input}>
            <DateTimeUI
              key_id={item.key}
              field={{
                value_obj: {
                  value: (data[item.key] && data[item.key].value) || "",
                  value2: (data[item.key] && data[item.key].value2) || "",
                },
              }}
              date_position={"value"}
              time_position={"value2"}
              validation={item.validation}
              onUpdate={(valueObj) => {
                const obj = cloneDeep(data);
                if (!obj[item.key] || obj[item.key].constructor !== Object) {
                  obj[item.key] = {};
                }
                obj[item.key].value = valueObj.value;
                obj[item.key].value2 = valueObj.value2;
                obj[item.key].value_name = item.value_name;
                obj[item.key].value2_name = item.value2_name;
                setData(obj);
              }}
              setParentFieldError={setParentFieldError}
            />
          </div>
        </div>
        <DividerLine />
      </React.Fragment>
    );
  else if (item.type === "year") {
    return (
      <React.Fragment key={item.key}>
        <div className={styles.row}>
          <div className={styles.label}>{item.text}</div>
          <div className={styles.center_input}>
            <YearUI
              key_id={item.key}
              field={{
                value_obj: {
                  value: (data[item.key] && data[item.key].value) || "",
                },
              }}
              onUpdate={(value) => {
                const obj = cloneDeep(data);
                if (!obj[item.key] || obj[item.key].constructor !== Object) {
                  obj[item.key] = {};
                }
                obj[item.key].value = value;
                obj[item.key].value_name = item.value_name;
                setData(obj);
              }}
              item_specific_error_validator={item.item_specific_error_validator}
              setParentFieldError={setParentFieldError}
            />
          </div>
        </div>
        <DividerLine />
      </React.Fragment>
    );
  } else if (item.type === "radio") {
    return (
      <React.Fragment key={item.key}>
        <div className={styles.row}>
          <div className={styles.label}>{item.text}</div>
          <div className={styles.center_input}>
            <RadioGroupUI
              options={item.options}
              field={{
                value_obj: {
                  value: (data[item.key] && data[item.key].value) || "",
                },
              }}
              value_position="value"
              onUpdate={(valueObj) => {
                const obj = cloneDeep(data);
                if (!obj[item.key] || obj[item.key].constructor !== Object) {
                  obj[item.key] = {};
                }
                obj[item.key].value = valueObj.value;
                obj[item.key].value_name = item.value_name;
                setData(obj);
              }}
            />
          </div>
        </div>
        <DividerLine />
      </React.Fragment>
    );
  } else if (item.type === "weight") {
    return (
      <React.Fragment key={item.key}>
        <div className={styles.row}>
          <div className={styles.label}>{item.text}</div>
          <div className={styles.center_input}>
            <WeightUI
              key_id={item.key}
              field={{
                value_obj: {
                  value: (data[item.key] && data[item.key].value) || "",
                  value2: (data[item.key] && data[item.key].value2) || "",
                  value3: (data[item.key] && data[item.key].value3) || "",
                },
              }}
              onUpdate={(valueObj) => {
                const obj = cloneDeep(data);
                if (!obj[item.key] || obj[item.key].constructor !== Object) {
                  obj[item.key] = {};
                }
                obj[item.key].value = valueObj.value;
                obj[item.key].value2 = valueObj.value2;
                obj[item.key].value3 = valueObj.value3;
                obj[item.key].value_name = item.value_name;
                obj[item.key].value2_name = item.value2_name;
                obj[item.key].value3_name = item.value3_name;
                setData(obj);
              }}
              item_specific_error_validator={item.item_specific_error_validator}
              setParentFieldError={setParentFieldError}
            />
          </div>
        </div>
        <DividerLine />
      </React.Fragment>
    );
  } else if (item.type === "weight_old") {
    return (
      <React.Fragment key={item.key}>
        <div className={styles.row}>
          <div className={styles.label}>{item.text}</div>
          <div className={styles.center_input}>
            <div>
              <div className={styles.inner_row}>
                <div className={styles.inner_row2}>Value:</div>
                <IntUI
                  key_id={item.key}
                  length={3}
                  field={{
                    value_obj: {
                      value: (data[item.key] && data[item.key].value) || "",
                    },
                  }}
                  onUpdate={(valueObj) => {
                    const obj = cloneDeep(data);
                    if (
                      !obj[item.key] ||
                      obj[item.key].constructor !== Object
                    ) {
                      obj[item.key] = {};
                    }
                    obj[item.key].value = valueObj.value;
                    obj[item.key].value_name = item.value_name;
                    setData(obj);
                  }}
                />
              </div>
              <div className={styles.inner_row}>
                <div className={styles.inner_row2}>Unit:</div>
                <RadioGroupUI
                  options={[
                    { label: "kg", value: "kg" },
                    { label: "lbs", value: "lbs" },
                  ]}
                  field={{
                    value_obj: {
                      value2: (data[item.key] && data[item.key].value2) || "",
                    },
                  }}
                  value_position={"value2"}
                  onUpdate={(valueObj) => {
                    const obj = cloneDeep(data);
                    if (
                      !obj[item.key] ||
                      obj[item.key].constructor !== Object
                    ) {
                      obj[item.key] = {};
                    }
                    obj[item.key].value2 = valueObj.value2;
                    obj[item.key].value2_name = item.value2_name;
                    setData(obj);
                  }}
                />
              </div>
              <div className={styles.inner_row}>
                <div className={styles.inner_row2}>Measurement type:</div>
                <RadioGroupUI
                  options={[
                    { label: "Estimate", value: "estimate" },
                    { label: "Actual", value: "actual" },
                  ]}
                  field={{
                    value_obj: {
                      value3: (data[item.key] && data[item.key].value3) || "",
                    },
                  }}
                  value_position={"value3"}
                  onUpdate={(valueObj) => {
                    const obj = cloneDeep(data);
                    if (
                      !obj[item.key] ||
                      obj[item.key].constructor !== Object
                    ) {
                      obj[item.key] = {};
                    }
                    obj[item.key].value3 = valueObj.value3;
                    obj[item.key].value3_name = item.value3_name;
                    setData(obj);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <DividerLine />
      </React.Fragment>
    );
  } else if (item.type === "int") {
    return (
      <React.Fragment key={item.key}>
        <div className={styles.row}>
          <div className={styles.label}>{item.text}</div>
          <div className={styles.center_input}>
            <IntUI
              key_id={item.key}
              length={item.length || 2}
              field={{
                value_obj: {
                  value: (data[item.key] && data[item.key].value) || "",
                },
              }}
              value_position={"value"}
              onUpdate={(valueObj) => {
                const obj = cloneDeep(data);
                if (!obj[item.key] || obj[item.key].constructor !== Object) {
                  obj[item.key] = {};
                }
                obj[item.key].value = valueObj.value;
                obj[item.key].value_name = item.value_name;
                setData(obj);
              }}
              item_specific_error_validator={item.item_specific_error_validator}
              setParentFieldError={setParentFieldError}
            />
          </div>
        </div>
        <DividerLine />
      </React.Fragment>
    );
  } else if (item.type === "blood_pressure") {
    return (
      <React.Fragment key={item.key}>
        <div className={styles.row}>
          <div className={styles.label}>{item.text}</div>
          <BloodPressureUI
            key_id={item.key}
            field={{
              value_obj: {
                value: (data[item.key] && data[item.key].value) || "",
                value2: (data[item.key] && data[item.key].value2) || "",
              },
            }}
            systolic_position={"value"}
            diastolic_position={"value2"}
            onUpdate={(valueObj) => {
              const obj = cloneDeep(data);
              if (!obj[item.key] || obj[item.key].constructor !== Object) {
                obj[item.key] = {};
              }
              obj[item.key].value = valueObj.value;
              obj[item.key].value2 = valueObj.value2;
              obj[item.key].value_name = item.value_name;
              obj[item.key].value2_name = item.value2_name;
              setData(obj);
            }}
            setParentFieldError={setParentFieldError}
          />
        </div>
        <DividerLine />
      </React.Fragment>
    );
  } else if (item.type === "text") {
    return (
      <React.Fragment key={item.key}>
        <div className={styles.row}>
          <div className={styles.label}>{item.text}</div>
          <TextField
            placeholder=""
            value={data[item.key]?.value || ""}
            onChange={(e) => {
              const obj = cloneDeep(data);
              if (!obj[item.key] || obj[item.key].constructor !== Object) {
                obj[item.key] = {};
              }
              obj[item.key].value = e.target.value;
              setData(obj);
            }}
            autoComplete="off"
            sx={{
              "& .MuiInputBase-input": {
                padding: "5px",
                paddingLeft: "10px",
                textAlign: "left",
              },
            }}
            fullWidth
          />
        </div>
        <DividerLine />
      </React.Fragment>
    );
  } else {
    return null;
  }
};
