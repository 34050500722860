import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get, post } from "util/requests";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import AddSubjectMenu from "./components/add_subject_menu";
import ModifySubjectColumnsMenu from "./components/modify_subject_columns_menu";
import DownloadSubjectsMenu from "./components/download_subjects_menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AllDomainsTable from "./components/all_domains_table";
import DomainSpecificTable from "./components/domain_specific_table";
import router from "routes";
import moment from "moment";
import DomainTag from "./components/domain_tag";
import { CheckIcon } from "ui/icons";
import Tooltip from "@mui/material/Tooltip";

import MobileSubjectList from "./components/mobile_subject_list";

import styles from "./subjects.module.css";

const initial_column_set_all = [
  "id",
  "status",
  "site_id",
  "consent_status",
  "randomization_status",
  "edc_status",
  "sae_status",
  "consent_domains",
  "randomization_domains",
  "queries",
];
const all_column_set_all = (permissions) => [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   type: "number",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  {
    field: "site_id",
    headerName: "Site (Code)",
    width: 200,
    align: "left",
    headerAlign: "center",
    renderCell: ({ row }) => `${row.site_name} (${row.site_id})`,
  },
  // {
  //   field: "consent_status",
  //   headerName: "Consent Status",
  //   width: 120,
  //   align: "center",
  //   headerAlign: "center",
  // },
  {
    field: "randomization_status",
    headerName: "Randomized",
    width: 180,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      row.randomization_status === "okay" ? <CheckIcon fontSize="18px" /> : "",
  },
  // {
  //   field: "edc_status",
  //   headerName: "EDC Status",
  //   width: 120,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "sae_status",
  //   headerName: "AE/SAE Status",
  //   width: 120,
  //   align: "center",
  //   headerAlign: "center",
  // },
  {
    field: "consent_domains",
    headerName: "Consented Domains",
    width: 300,

    renderCell: ({ row }) => (
      <React.Fragment>
        {(row.consent_domains &&
          row.consent_domains.constructor === Array &&
          row.consent_domains.map((d) => (
            <div className={styles.domain_tile_wrapper}>
              <DomainTag text={d} />
            </div>
          ))) || <div />}
      </React.Fragment>
    ),
  },
  {
    field: "randomization_domains",
    headerName: "Randomized Domains",
    width: 300,

    renderCell: ({ row }) => (
      <React.Fragment>
        {(row.randomization_domains &&
          row.randomization_domains.constructor === Array &&
          row.randomization_domains.map((d) => (
            <div className={styles.domain_tile_wrapper}>
              <DomainTag text={d} />
            </div>
          ))) || <div />}
      </React.Fragment>
    ),
  },
  {
    field: "queries",
    headerName: "Queries",
    width: 100,
    renderCell: ({ row }) => {
      if (
        (permissions.includes("start_query") ||
          permissions.includes("resolve_query")) &&
        permissions.includes("respond_query") &&
        row.queries?.total
      ) {
        return (
          <React.Fragment>
            <Tooltip title="Open Queries" className={styles.query_margin}>
              {row.queries?.open}
            </Tooltip>
            /
            <Tooltip title="Responded Queries" className={styles.query_margin}>
              {row.queries?.responded}
            </Tooltip>
            /
            <Tooltip title="Total Queries" className={styles.query_margin}>
              {row.queries?.total}
            </Tooltip>
          </React.Fragment>
        );
      } else if (
        (permissions.includes("start_query") ||
          permissions.includes("resolve_query")) &&
        row.queries?.total
      ) {
        return (
          <React.Fragment>
            <Tooltip title="Responded Queries" className={styles.query_margin}>
              {row.queries?.responded}
            </Tooltip>
            /{" "}
            <Tooltip title="Total Queries" className={styles.query_margin}>
              {row.queries?.total}
            </Tooltip>
          </React.Fragment>
        );
      } else if (permissions.includes("respond_query") && row.queries?.total) {
        return (
          <React.Fragment>
            <Tooltip title="Open Queries" className={styles.query_margin}>
              {row.queries?.open}
            </Tooltip>
            /
            <Tooltip title="Total Queries" className={styles.query_margin}>
              {row.queries?.total}
            </Tooltip>
          </React.Fragment>
        );
      } else {
        return "";
      }
    },
  },
];

const initial_column_set_domain = [
  "id",
  "status",
  "site_id",
  "randomization_date",
  "consent_received",
  "consent_date",
  "sae_status",
];
const all_columns_domain = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "site_id",
    headerName: "Site (Code)",
    width: 100,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => `${row.site_name} (${row.site_id})`,
  },
  {
    field: "randomization_date",
    headerName: "Randomization Date",
    width: 180,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      (row.randomization_date &&
        moment(row.randomization_date).format("yyyy-MMM-DD")) ||
      "",
  },
  {
    field: "consent_received",
    headerName: "Consent Received",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "consent_date",
    headerName: "Consent Date",
    width: 280,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      (row.consent_date && moment(row.consent_date).format("yyyy-MMM-DD")) ||
      "",
  },
  {
    field: "sae_status",
    headerName: "AE/SAE Status",
    width: 280,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "sae_date",
    headerName: "AE/SAE Date",
    width: 280,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "user_randomized_by_name",
    headerName: "Randomized By",
    width: 180,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "consent_type",
    headerName: "Consent Type",
    width: 180,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "consent_version",
    headerName: "Consent Version",
    width: 180,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "consented_by",
    headerName: "Consented By",
    width: 180,
    align: "center",
    headerAlign: "center",
  },
  // {
  //   field: "primary_outcome_1",
  //   headerName: "Primary Outcome 1",
  //   width: 180,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "primary_outcome_2",
  //   headerName: "Primary Outcome 2",
  //   width: 180,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "primary_outcome_3",
  //   headerName: "Primary Outcome 3",
  //   width: 180,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "primary_outcome_4",
  //   headerName: "Primary Outcome 4",
  //   width: 180,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "secondary_outcome_1",
  //   headerName: "Secondary Outcome 1",
  //   width: 180,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "secondary_outcome_2",
  //   headerName: "Secondary Outcome 2",
  //   width: 180,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "secondary_outcome_3",
  //   headerName: "Secondary Outcome 3",
  //   width: 180,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "secondary_outcome_4",
  //   headerName: "Secondary Outcome 4",
  //   width: 180,
  //   align: "center",
  //   headerAlign: "center",
  // },
];

export default function Subjects() {
  const navigate = useNavigate();
  const location = useLocation();

  const permissions = useSelector((state) => state.user.userData.permissions);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [patientData, setPatientData] = useState([]);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [isColumnMenuOpen, setColumnMenuOpen] = useState(false);
  const [isDownloadMenuOpen, setDownloadMenuOpen] = useState(false);
  const [selectedColumnsDomain, setSelectedColumnsDomain] = useState(
    initial_column_set_domain,
  );
  const [selectedColumnsAll, setSelectedColumnsAll] = useState(
    initial_column_set_all,
  );
  const [selectedDomain, setSelectedDomain] = useState("all");
  const [options, setOptions] = useState([{ code: "all", name: "Any" }]);
  const subjectMenuRef = useRef();
  const columnMenuRef = useRef();
  const downloadMenuRef = useRef();
  const columns_domain = all_columns_domain.filter((c) =>
    selectedColumnsDomain.includes(c.field),
  );
  const columns_all = all_column_set_all(permissions).filter((c) =>
    selectedColumnsAll.includes(c.field),
  );

  const userDomains = useSelector((state) => state.user.userData.domains);
  const active_study_id = useSelector((state) => state.study.active_study_id);

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "subjects_table",
    });
  }, [location.pathname]);

  const handleDocumentClick = (event) => {
    if (
      event.target &&
      columnMenuRef &&
      columnMenuRef.current &&
      !columnMenuRef.current.contains(event.target)
    ) {
      setColumnMenuOpen(false);
    }
    if (
      event.target &&
      subjectMenuRef &&
      subjectMenuRef.current &&
      !subjectMenuRef.current.contains(event.target)
    ) {
      setSubMenuOpen(false);
    }
    if (
      event.target &&
      downloadMenuRef &&
      downloadMenuRef.current &&
      !downloadMenuRef.current.contains(event.target)
    ) {
      setDownloadMenuOpen(false);
    }
  };
  useEffect(() => {
    get(`/invite_user_sites_and_roles/${active_study_id}`).then(({ data }) => {
      setOptions(
        options.concat(
          data.domains.filter((d) => userDomains.includes(d.code)),
        ),
      );
    });
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  const setColumnShowingState = (field, is_showing) => {
    let columns = [...selectedColumnsDomain];
    if (selectedDomain === "all") {
      columns = [...selectedColumnsAll];
    }
    if (is_showing) {
      columns.push(field);
    } else {
      columns = columns.filter((c) => c !== field);
    }
    if (selectedDomain === "all") {
      setSelectedColumnsAll(columns);
    } else {
      setSelectedColumnsDomain(columns);
    }
  };

  if (window.innerWidth >= 480)
    return (
      <div className={styles.flex}>
        <div className={styles.table_header_block}>
          <div className={styles.header_block_left}>
            <h3>Subjects</h3>
            {permissions.includes("randomization_access") ? (
              <div>
                <AddCircleOutlineIcon
                  className={styles.table_action_icon}
                  onClick={() => setSubMenuOpen(true)}
                />
                {isSubMenuOpen ? (
                  <AddSubjectMenu ref={subjectMenuRef} router={router} />
                ) : null}
              </div>
            ) : null}
          </div>
          {/*
          <div className={styles.domain_drop_wrapper}>
            <div className={styles.domain_drop_title}>Queries</div>
            <Select
              value={selectedDomain}
              sx={{
                fontSize: 14,
                minWidth: 200,
                height: 30,
                padding: 0,
                alignSelf: "center",
              }}
              onChange={(e) => {
                setSelectedDomain(e.target.value);
              }}
            >
              {["any", "open", "responded"].map((o) => (
                <MenuItem key={o} value={o}>
                  {o}
                </MenuItem>
              ))}
            </Select>
          </div>*/}

          {/*<div className={styles.domain_drop_wrapper}>
            <div className={styles.domain_drop_title}>Domain</div>
            <Select
              value={selectedDomain}
              sx={{
                fontSize: 14,
                minWidth: 200,
                height: 30,
                padding: 0,
                alignSelf: "center",
              }}
              onChange={(e) => {
                setSelectedDomain(e.target.value);
              }}
            >
              {options &&
                options.length &&
                options.map((o) => (
                  <MenuItem key={o.code} value={o.code}>
                    {o.name}
                  </MenuItem>
                ))}
            </Select>
          </div>*/}
          <div>
            <TextField
              placeholder="Search subjects"
              onChange={() => {}}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  marginLeft: "10px",
                  textAlign: "left",
                  minWidth: "350px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      sx={{ color: "#999", cursor: "pointer" }}
                      onClick={() => {}}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <FileDownloadIcon
              className={styles.table_action_icon}
              onClick={() => setDownloadMenuOpen(true)}
            />
            {isDownloadMenuOpen ? (
              <DownloadSubjectsMenu
                ref={downloadMenuRef}
                selectedDomain={selectedDomain}
                selectedColumns={
                  selectedDomain === "all"
                    ? selectedColumnsAll
                    : selectedColumnsDomain
                }
                all_column_set_all={all_column_set_all(permissions)}
                all_columns_domain={all_columns_domain}
              />
            ) : null}
            <ViewWeekIcon
              className={styles.table_action_icon}
              onClick={() => setColumnMenuOpen(true)}
            />
            {isColumnMenuOpen ? (
              <ModifySubjectColumnsMenu
                ref={columnMenuRef}
                availableColumns={
                  selectedDomain === "all"
                    ? all_column_set_all(permissions)
                    : all_columns_domain
                }
                selectedColumns={
                  selectedDomain === "all"
                    ? selectedColumnsAll
                    : selectedColumnsDomain
                }
                setColumnShowingState={setColumnShowingState}
              />
            ) : null}
          </div>
        </div>
        {selectedDomain === "all" ? (
          <div className={styles.table_wrapper}>
            <AllDomainsTable
              columns_all={columns_all}
              study_id={(active_study_id && parseInt(active_study_id)) || 1}
            />
          </div>
        ) : (
          <div style={{ height: 600, width: "100%" }}>
            <DomainSpecificTable
              columns_domain={columns_domain}
              selectedDomain={selectedDomain}
            />
          </div>
        )}
      </div>
    );
  else {
    return <MobileSubjectList study_id={active_study_id} />;
  }
}
