import { useSelector } from "react-redux";
import RandomizeSubjectACT from "./act";
import RandomizeSubjectRIC from "./ric";

export default function RandomizeSubject() {
  const study_id = useSelector((state) => state.user.userData.active_study_id);
  if (study_id === 1) {
    return <RandomizeSubjectACT />;
  } else if (study_id === 4) {
    return <RandomizeSubjectRIC />;
  }
}
