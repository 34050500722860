import React, { useState, useRef, useEffect } from "react";
import styles from "./blood_pressure_date_time.module.css";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import DateTimeUI from "../date_time/components/date_time_ui";
import BloodPressureUI from "../blood_pressure/components/blood_pressure_ui";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import RadioGroupUI from "ui/crfs/radio_group/components/radio_group_ui";
import { cloneDeep } from "lodash";

let tempStorage = null;

export default function BloodPressureDateTime(props) {
  const { field, title, onUpdate, enableQuery, crfStatus } = props;
  const [localVal, setLocalVal] = useState((field && field.value_obj) || {});
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  const systolic_position = "value";
  const diastolic_position = "value2";
  const date_position = "value3";
  const time_position = "value4";
  const not_done_position = "value5";

  const acceptTempStorage = () => {
    onUpdate(localVal);
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };

  const updateValue = (key, value) => {
    let obj = cloneDeep(localVal);
    tempStorage = cloneDeep(localVal);
    if (key === "datetime") {
      obj[date_position] = value[date_position];
      obj[time_position] = value[time_position];
    }
    if (key === "blood_pressure") {
      obj[systolic_position] = value[systolic_position];
      obj[diastolic_position] = value[diastolic_position];
    }
    if (key === "not_done") {
      obj[not_done_position] = value[not_done_position];
    }
    setLocalVal(obj);
    if (crfStatus === "data_complete") {
      setShowingCompleteChangeModal(true);
      return;
    }
    onUpdate(obj);
  };

  useEffect(() => {
    let obj = { ...field.value_obj };
    obj.value_name = field.value_name || "";
    obj.value2_name = field.value2_name || "";
    obj.value3_name = field.value3_name || "";
    obj.value4_name = field.value4_name || "";
    obj.value5_name = field.value5_name || "";
    setLocalVal(obj);
  }, []);

  const closeModal = () => {
    setLocalVal(tempStorage);
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          {enableQuery ? (
            <div>
              <NotificationWidget {...props} />
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>
        <div className={styles["right-split"]}>
          <BloodPressureUI
            {...props}
            onUpdate={(value_obj) => {
              updateValue("blood_pressure", value_obj);
            }}
            field={{ ...field, value_obj: localVal }}
            systolic_position={systolic_position}
            diastolic_position={diastolic_position}
          />
          <DateTimeUI
            key={field.id}
            {...props}
            field={{ ...field, value_obj: localVal }}
            onUpdate={(value_obj) => {
              updateValue("datetime", value_obj);
            }}
            date_position={date_position}
            time_position={time_position}
          />
        </div>
      </div>
      <div className={styles.input_flex}>
        <div className={styles["right-split"]}></div>
        <div className={styles["right-split"]}>
          <RadioGroupUI
            {...props}
            onUpdate={(value_obj) => updateValue("not_done", value_obj)}
            field={{ ...field, value_obj: localVal }}
            options={[{ label: "Not Done", value: "not_done" }]}
            value_position={"value5"}
            value_name_position={"value5_name"}
          />
        </div>
      </div>
      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
