import React, { useState, useRef, useEffect } from "react";
import styles from "../weight.module.css";
import { TextField } from "@mui/material";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import Radio from "@mui/material/Radio";
import { cloneDeep } from "lodash";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";

export default function WeightUI(props) {
  const {
    key_id,
    field,
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    crf_id,
    field_id,
    query,
    setQuery,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    enableQuery,
    show_checkmark,
    length,
    units,
    placeHolder,
    read_only,
    crfStatus,
    setParentFieldError,
    item_specific_error_validator,
  } = props;

  const [valueObj, setValueObj] = useState((field && field.value_obj) || {});
  const ref = useRef(null);

  useEffect(() => {
    setValueObj(field && field.value_obj);
  }, [field && field.value_obj]);

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      ref.current.blur();
    }
  };

  const updateValue = (value_obj) => {
    // if (crfStatus === "data_complete") {
    //   setShowingCompleteChangeModal(true);
    //   return;
    // }
    onUpdate(value_obj);
  };

  const textChange = (text) => {
    let obj = cloneDeep(valueObj);
    obj.value = text;
    setValueObj(obj);
    updateValue(obj);
  };

  const onRadioChange = (key, input) => {
    let obj = cloneDeep(valueObj);
    if (key === "unit") {
      obj.value2 = input;
    } else {
      obj.value3 = input;
    }
    updateValue(obj);
    setValueObj(obj);
    if (is_error(obj)) {
      setParentFieldError && setParentFieldError(key_id, true);
    } else {
      setParentFieldError && setParentFieldError(key_id, false);
    }
  };

  const onBlur = () => {
    updateValue(valueObj);
  };

  const currentValueDisplay =
    (valueObj &&
      valueObj.value &&
      valueObj.value2 &&
      valueObj.value3 &&
      `${valueObj.value} ${valueObj.value2} (${valueObj.value3})`) ||
    "---";

  const is_error = (valueObj) => {
    if (!valueObj.value) {
      return false;
    }
    if (valueObj.value.length === 0) {
      return false;
    } else {
      let local_validation = () => !/^[0-9]{2,3}$/.test(valueObj.value);
      let error = local_validation();
      if (
        item_specific_error_validator &&
        item_specific_error_validator.constructor === Function
      ) {
        error = local_validation() || item_specific_error_validator(valueObj);
      }

      // setParentFieldError && setParentFieldError(key, error);
      return error;
    }
  };

  return (
    <React.Fragment>
      {field.read_only || read_only ? <div>{currentValueDisplay}</div> : null}
      {!field.read_only && !read_only ? (
        <React.Fragment>
          <div className={styles.row}>
            <div className={styles.label}>Value</div>
            <TextField
              type="text"
              inputRef={ref}
              placeholder={placeHolder || ""}
              value={valueObj.value}
              onChange={(e) => {
                let value = e.target.value;
                if (/^([0-9]+){0,3}$/.test(value)) {
                  setValueObj({ ...valueObj, value });
                }
                if (is_error({ ...valueObj, value })) {
                  setParentFieldError && setParentFieldError(key_id, true);
                } else {
                  setParentFieldError && setParentFieldError(key_id, false);
                }
              }}
              inputProps={{ maxLength: 3 }}
              autoComplete="off"
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  textAlign: "center",
                  width: "50px",
                  fontSize: 14,
                },
              }}
              error={is_error(valueObj)}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Unit</div>
            <div className={styles.radio_group}>
              <div
                className={styles.radio_item_wrapper}
                onClick={() => {
                  onRadioChange("unit", "kg");
                }}
              >
                <Radio
                  size="small"
                  checked={valueObj.value2 === "kg"}
                  onChange={(e) => {
                    if (e.target.checked) onRadioChange("unit", "kg");
                  }}
                  sx={{
                    padding: "0px",
                    marginRight: "4px",
                  }}
                />
                <div className={styles.radio_text}>kg</div>
              </div>{" "}
              <div
                className={styles.radio_item_wrapper}
                onClick={() => onRadioChange("unit", "lbs")}
              >
                <Radio
                  size="small"
                  checked={valueObj.value2 === "lbs"}
                  onChange={(e) => {
                    if (e.target.checked) onRadioChange("unit", "lbs");
                  }}
                  sx={{
                    padding: "0px",
                    marginRight: "4px",
                  }}
                />
                <div className={styles.radio_text}>lbs</div>
              </div>
            </div>
          </div>
          {!field.exclude_actual_or_estimate ? (
            <div className={styles.row}>
              <div className={styles.label}>Measurement Type</div>
              <div className={styles.radio_group}>
                <div
                  className={styles.radio_item_wrapper}
                  onClick={() => {
                    onRadioChange("accuracy", "estimate");
                  }}
                >
                  <Radio
                    size="small"
                    checked={valueObj.value3 === "estimate"}
                    onChange={(e) => {
                      if (e.target.checked)
                        onRadioChange("accuracy", "estimate");
                    }}
                    sx={{
                      padding: "0px",
                      marginRight: "4px",
                    }}
                  />
                  <div className={styles.radio_text}>Estimate</div>
                </div>{" "}
                <div
                  className={styles.radio_item_wrapper}
                  onClick={() => {
                    onRadioChange("accuracy", "actual");
                  }}
                >
                  <Radio
                    size="small"
                    checked={valueObj.value3 === "actual"}
                    onChange={(e) => {
                      if (e.target.checked) onRadioChange("accuracy", "actual");
                    }}
                    sx={{
                      padding: "0px",
                      marginRight: "4px",
                    }}
                  />
                  <div className={styles.radio_text}>Actual</div>
                </div>
              </div>
            </div>
          ) : null}
          {units ? (
            <div className={styles.input_description}>{units}</div>
          ) : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
