import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./sidebar.module.css";
import { get } from "util/requests";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import SmsOutlined from "@mui/icons-material/ModeCommentOutlined";
import router from "routes";
import { NotifyIcon, RespondedIcon, ResolvedIcon, CheckIcon } from "ui/icons";

const layer1 = [styles.sidebar_layer, styles.sidebar_layer1].join(" ");
const layer2 = [styles.sidebar_layer, styles.sidebar_layer2].join(" ");
const layer2_active = [
  styles.sidebar_layer,
  styles.sidebar_layer2,
  styles.layer2_active,
].join(" ");
const layer3 = [styles.sidebar_layer, styles.sidebar_layer3].join(" ");
const layer3_active = [
  styles.sidebar_layer,
  styles.sidebar_layer3,
  styles.layer2_active,
].join(" ");

const renderRowIcon = (props) => {
  const { crf_status } = props;
  if (crf_status === "checked") {
    return <CheckIcon fontSize={"16px"} />;
  } else if (crf_status === "open") {
    return <NotifyIcon fontSize={"16px"} />;
  } else if (crf_status === "resolved") {
    return <ResolvedIcon fontSize={"16px"} />;
  } else {
    return (
      <PanoramaFishEyeIcon
        sx={{ color: "#bbb", fontSize: "16px", lineHeight: "16px" }}
      />
    );
  }
};

const Row = function (props) {
  const { site_id, study_id, subject_id } = props;
  return (
    <div
      className={props.active ? layer3_active : layer3}
      onClick={() =>
        props.navigate(
          `/study/${study_id}/site/${site_id}/subjects/${subject_id}/view`,
        )
      }
    >
      <div>{props.name}</div>
      <div>{renderRowIcon(props)}</div>
    </div>
  );
};

export default function EDCGridSideBar(props) {
  const [subjectData, setSubjectData] = useState([]);
  const active_study_id = useSelector((state) => state.study.active_study_id);

  useEffect(() => {
    get(`/patient_grid_sidebar_data/${active_study_id || 1}`).then((ret) =>
      setSubjectData(ret.data),
    );
  }, []);
  return (
    <div className={styles.sidebar}>
      <div className={layer2}>
        <div>
          <strong>Subjects</strong>
        </div>
      </div>
      {subjectData &&
        subjectData.map((subject) => (
          <Row
            key={subject.id}
            name={subject.id}
            subject_id={subject.id}
            study_id={active_study_id}
            site_id={props.site_id}
            active={subject.id === parseInt(props.subject_id)}
            crf_status={subject.crf_status}
            navigate={router.navigate}
          />
        ))}
    </div>
  );
}
