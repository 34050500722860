import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get } from "util/requests";
import colors from "colors";
import moment from "moment";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "subject_id",
    headerName: "Subject ID",
    width: 90,
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    width: 90,
    align: "center",
    headerAlign: "center",
  },

  {
    field: "crf_name",
    headerName: "CRF",
    width: 300,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "visit",
    headerName: "Visit",
    width: 60,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "crf_field_id",
    headerName: "Field ID",
    width: 100,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "modified_on",
    headerName: "Modified",
    width: 160,
    renderCell: ({ row }) => {
      return moment(row.modified_on).format("yyyy-MMM-DD HH:mm:ss");
    },
  },
  {
    field: "created_on",
    headerName: "Created",
    width: 160,
    renderCell: ({ row }) => {
      return moment(row.created_on).format("yyyy-MMM-DD HH:mm:ss");
    },
  },
];

export default function QueryTable(props) {
  const { subject_id } = props;
  const [queries, setQueries] = useState([]);

  useEffect(() => {
    if (subject_id) {
      get(`/queries/${subject_id}`).then((ret) => setQueries(ret.data));
    } else {
      get("/queries").then((ret) => setQueries(ret.data));
    }
  }, [subject_id]);

  return (
    <DataGrid
      rows={queries}
      columns={columns}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      autoPageSize
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          // backgroundColor: "#f7f3fe",
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      onRowClick={(data) => {
        // console.log("row", row);
        router.navigate(data.row.pathname);
      }}
    />
  );
}
