import React, { useState, useEffect } from "react";
import styles from "./randomize.module.css";
import { post } from "util/requests";
import LoadingButton from "@mui/lab/LoadingButton";
import RadioGroup from "ui/crfs/radio_group";
import RadioGroupUI from "ui/crfs/radio_group/components/radio_group_ui";
import DropDown from "ui/crfs/drop_down";
import DateTimeUI from "ui/crfs/date_time/components/date_time_ui";
import BloodPressureUI from "ui/crfs/blood_pressure/components/blood_pressure_ui";
import YearUI from "ui/crfs/year/components/year_ui";
import IntUI from "ui/crfs/int/components/int_ui";
import DividerLine from "ui/crfs/divider_line";
import { useParams, useLocation } from "react-router-dom";
import { get } from "util/requests";
import { Checkbox } from "@mui/material";
import RandomizeModal from "./components/randomize_modal";
import router from "routes";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";
import { renderQuestion } from "./helpers";

export default function RandomizeSubjectRIC() {
  const user_data = useSelector((state) => state.user.userData);
  const [is_loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initData, setInitData] = useState({});
  const [data, setData] = useState({ confirmation: false });
  const { consentID } = useParams();
  const [subjectID, setSubjectID] = useState(null);
  const location = useLocation();
  const [fieldErrorFlags, setFieldErrorFlags] = useState({});

  useEffect(() => {
    new Promise((resolve) => {
      get(`/subject/by_consent_id/${consentID}`).then((ret) => {
        setInitData(ret.data);
        const subjectID = ret.data.subjectID;
        setSubjectID(subjectID);

        if (ret.data.existing_data) {
          bulkChangeValue({ subjectID, consentID, ...ret.data.existing_data });
        } else {
          bulkChangeValue({ subjectID, consentID });
        }
        resolve(subjectID);
      });
    }).then((subject_id) => {
      post("/footprint", {
        path: location.pathname,
        action: "pageload",
        name: "randomization_entry",
        subject_id,
      });
    });
  }, []);

  const questions = [
    {
      key: "device_id",
      text: "Device ID",
      type: "text",
      value_name: "device_id",
      crf_id: "4d889a0f-0738-442b-a019-043c880d2447",
      crf_field_id: 1,
    },
  ];

  const handleRandomizeCheck = (state) => {
    const obj = cloneDeep(data);
    obj.site = user_data.site;
    obj.site_code = user_data.site_code;
    obj.time_zone = user_data.time_zone;
    obj.domain_code = initData.domain_code;
    setData(obj);
    setIsModalOpen(state);
  };
  const handleFieldChange = () => {};
  const handleRandomize = ({ password, modal_confirmation }) => {
    return new Promise((resolve) => {
      setLoading(!is_loading);
      let now = new Date();
      let obj = Object.assign(data, {
        password,
        modal_confirmation,
        questions,
        date: moment(now).format("YYYY-MM-DD"),
        time: moment(now).format("HH:mm"),
        study_id: user_data.active_study_id,
      });
      post("/subject/randomize", obj)
        .then((ret) => {
          const { randomization_id } = ret.data;
          router.navigate(`/randomize/result/${randomization_id}`);
        })
        .then(() => resolve({ success: true }))
        .catch(() => resolve({ success: false }));
    });
  };

  const bulkChangeValue = (obj) => {
    let newData = Object.assign({ ...data }, obj);
    setData(newData);
  };

  const changeValue = (key, value) => {
    let newData = { ...data };
    newData[key] = value;
    setData(newData);
  };
  const getValue = (key) => {
    return data[key] || null;
  };

  const setFieldError = (field, is_error) => {
    if (is_error) {
      // let _fieldErrorFlags = cloneDeep(fieldErrorFlags);
      let _fieldErrorFlags = fieldErrorFlags;
      _fieldErrorFlags[field] = true;
      setFieldErrorFlags(_fieldErrorFlags);
    } else {
      delete fieldErrorFlags[field];
      setFieldErrorFlags(fieldErrorFlags);
    }
  };

  const consent_type_options = [
    "Patient/regular",
    "Surrogate",
    "Physician/2 physician",
  ].map((r) => ({ label: r, value: r.toLowerCase().replaceAll(" ", "-") }));
  const consent_method_options = ["Electronic", "Email", "Paper"].map((r) => ({
    label: r,
    value: r.toLowerCase().replaceAll(" ", "-"),
  }));

  const disabled = () => {
    if (!data.confirmation) {
      return true;
    }

    let not_all_questions_filled = questions.reduce((accum, item) => {
      if (["int", "year", "radio"].includes(item.type)) {
        if (!data[item.key] || !data[item.key].value) {
          return true;
        } else {
          return accum;
        }
      } else if (item.type === "weight") {
        if (
          !data[item.key] ||
          !data[item.key].value ||
          !data[item.key].value2 ||
          !data[item.key].value3
        ) {
          return true;
        } else {
          return accum;
        }
      } else if (item.type === "datetime") {
        if (
          !data[item.key] ||
          !data[item.key].value ||
          !data[item.key].value2
        ) {
          return true;
        } else {
          return accum;
        }
      } else {
        return accum;
      }
    }, false);

    let any_field_validation_flags = Object.keys(fieldErrorFlags).length > 0;

    if (not_all_questions_filled || any_field_validation_flags) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.title}>
          Subject ID: <span className={styles.subject_id}>{subjectID}</span>
        </div>
        <div className={styles.title2}>
          {initData.domain_name} Randomization
        </div>
      </div>

      {questions.map((question) =>
        renderQuestion(data, setData, question, setFieldError),
      )}

      <div
        className={styles.confirmation}
        onClick={() => {
          const obj = cloneDeep(data);
          obj.confirmation = !obj.confirmation;
          setData(obj);
        }}
      >
        <Checkbox checked={data.confirmation} className={styles.checkbox} />
        <div>
          I CONFIRM that the subject meets eligibility criteria, the consent
          process has been completed according to local requirements, and is
          ready to be randomized.
        </div>
      </div>

      <DividerLine />
      <div className={styles.button_wrapper}>
        <LoadingButton
          variant="contained"
          loading={is_loading}
          onClick={handleRandomizeCheck}
          disabled={disabled()}
        >
          Randomize
        </LoadingButton>
      </div>
      <RandomizeModal
        showing={isModalOpen}
        subject_id={subjectID}
        domain_name={initData.domain_name || ""}
        closeRandomizationModal={() => setIsModalOpen(false)}
        handleRandomize={handleRandomize}
        questions={questions}
        data={data}
      />
    </div>
  );
}
