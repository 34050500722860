import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import VisitMap from "./components/visit_map";
import styles from "./edc_grid.module.css";
import EdcGridSidebar from "./components/sidebar";
import { useParams, useLocation } from "react-router-dom";
import { get, post } from "util/requests";
import SubjectQueryView from "./components/subject_query_view";
import DomainTag from "views/subjects/components/domain_tag";
import ConsentView from "./components/consent_view";
import DeviationsView from "./components/deviations_view";
import router from "routes";
import { useSelector } from "react-redux";
import { arrayHaveCommonItems } from "util/helpers";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

export default function EDCGrid(props) {
  const [value, setValue] = useState(0);
  const { subject_id, site_id } = useParams();
  const [patientStatus, setPatientStatus] = useState({
    consent_domains: [],
    randomization_domains: [],
  });
  const permissions = useSelector((state) => state.user.userData.permissions);
  const study_id = useSelector((state) => state.user.userData.active_study_id);
  const location = useLocation();

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "edc_grid_base",
      study_id,
      subject_id,
    });
  }, [location.pathname]);

  useEffect(() => {
    get(`/patient_study_info/${subject_id}`)
      .then((ret) => {
        if (ret.data.status) {
          setPatientStatus(ret.data);
        }
      })
      .catch(() => router.navigate("/subjects"));
  }, [subject_id]);

  const handleChange = (event, newValue) => {
    console.log("newvalue", newValue);
    setValue(newValue);
  };

  return (
    <div className={styles.wrapper}>
      <EdcGridSidebar subject_id={subject_id} site_id={site_id} />
      <div className={styles.tab_wrapper}>
        <div className={styles.flex}>
          <div className={styles.subject_title}>
            <strong>Subject: {subject_id}</strong>
          </div>
          <div>
            <div className={styles.tiles}>
              <div className={styles.tile_label}>Randomized:</div>
              {patientStatus.randomization_domains.map((domain) => (
                <DomainTag text={domain} key={domain} />
              ))}
            </div>
            <div className={styles.tiles}>
              <div className={styles.tile_label}>Consented:</div>
              {patientStatus.consent_domains.map((domain) => (
                <DomainTag text={domain} key={domain} />
              ))}
            </div>
          </div>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Visit Map"
            {...a11yProps(0)}
            sx={{
              "&.MuiTab-root": {},
              "&.Mui-selected": {
                borderBottom: "0px",
              },
            }}
          />
          <Tab label="Queries" {...a11yProps(1)} />
          {arrayHaveCommonItems(permissions, [
            "consent_record_view",
            "consent_record_update",
          ]) ? (
            <Tab label="Consent" {...a11yProps(2)} />
          ) : null}
          <Tab label="Deviations" {...a11yProps(3)} />
        </Tabs>

        <TabPanel value={value} index={0} key={0}>
          <div className={styles.tab_panel}>
            <VisitMap subject_id={subject_id} site_id={site_id} />
          </div>
        </TabPanel>

        <TabPanel value={value} index={1} key={1}>
          <div className={styles.tab_panel}>
            <SubjectQueryView subject_id={subject_id} site_id={site_id} />
          </div>
        </TabPanel>

        {arrayHaveCommonItems(permissions, [
          "consent_record_view",
          "consent_record_update",
        ]) ? (
          <TabPanel value={value} index={2} key={2}>
            <div className={styles.tab_panel}>
              <ConsentView subject_id={subject_id} study_id={study_id} />
            </div>
          </TabPanel>
        ) : null}
        <TabPanel
          value={value}
          index={
            arrayHaveCommonItems(permissions, [
              "consent_record_view",
              "consent_uploaded_document_view",
              "consent_record_update",
            ])
              ? 3
              : 2
          }
          key={
            arrayHaveCommonItems(permissions, [
              "consent_record_view",
              "consent_uploaded_document_view",
              "consent_record_update",
            ])
              ? 3
              : 2
          }
        >
          <div className={styles.tab_panel}>
            <DeviationsView subject_id={subject_id} />
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
